import React from "react";

import lpGroupsImg from "../../images/landingpage/landing-group-new2.png";

export default function LpGroups() {
    return (
        <div className="lp-groups">
            
            {/*Top text*/}
            <p className="lp-groups-text">
                Join Groups, Learn Together.
            </p>

            {/*row flex containing img and text content */}
            <div className="lp-groups-img-cont">

            <img src={lpGroupsImg} className="lp-groups-img" />

                {/*Column flex containing text and button */}
                <div className="lp-groups-right">
                    <p className="lp-groups-text-small">
                        Learning in groups enriches your experience. Join fellow peers to exchange ideas, ask questions, and deepen your understanding.
                    </p>

                    <button className="lp-groups-btn">
                        Popular Groups <span style={{marginLeft:"10px"}}>&rarr;</span>
                    </button>
                </div>

                

            </div>

        </div>
    )
}