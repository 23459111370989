import React from "react";
import moveIcon from "../../images/icons/move.png";


export default function DndWidget(props) {

    //state to control when the widget is draggable
    const [draggable, setDraggable] = React.useState(false);

    
    return (
        <div 
            className="dnd-widget"
            draggable={draggable}
            onDragStart={props.handleOnDrag}>
            
            <div className="dnd-widget-topbar">
                <p className="dnd-widget-title">{props.title}</p>
                <img 
                    className="dnd-widget-moveicon" 
                    src={moveIcon} 
                    draggable="false"
                    onMouseEnter={()=>setDraggable(true)}
                    onMouseLeave={()=>setDraggable(false)} />
            </div>
            <div className="dnd-widget-elementwrapper">
                {props.element}
            </div>

        </div>
    )
}