import React from "react";

export default function LpReviews() {
    return (
        <div className="lp-reviews">
            <p className="lp-reviews-title">
                What Students Have to Say
            </p>


            <div className="lp-reviews-reviewcontainer">
                <p className="lp-reviews-review">
                &#x201C; Before SkillSage I was nothing - literally dirt. SkillSage's plenty of built-in features saved my life&#x201D;
                </p>
                <p className="lp-reviews-reviewer">
                    - Brian S, SkillSage Student
                </p>
            </div>


            <div className="lp-reviews-reviewcontainer">
                <p className="lp-reviews-review">
                &#x201C; I always tell people 'If SkillSage were good, I would use it' &#x201D;
                </p>
                <p className="lp-reviews-reviewer">
                    - Akash R, SkillSage Student
                </p>
            </div>
        </div>
    )
}