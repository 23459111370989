import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import * as Yup from 'yup';

import googleLogo from "../images/google-logo.png";
import facebookLogo from "../images/facebook-logo.png";


export default function Login(props) {

    const initialValues = {
        username: "",
        password: "",
        rememberme: false
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('*Username is required'),
        password: Yup.string().min(7, '*Must contain at least 7 characters').required('*Password is required')
    });

    React.useEffect(()=>{
        if (props.userInfo.userId!==null) {
            navigate("/")
        }
    }, [])

    const navigate = useNavigate();

    const logInSubmit = (data) => {
        axios.post("https://api.skillsagelearn.com/user/verify", {
            username:data.username,
            password:data.password
        })
            .then((response)=>{
                const responseInfo = response.data;
                if (responseInfo.status=="error") {
                    throw new Error(responseInfo.error.message)
                }
                else if (!responseInfo.data.userFound) {
                    console.log("No user found!")
                }
                else if (!responseInfo.data.validated) {
                    console.log("Incorrect password")
                }
                else {
                    localStorage.setItem('skillsage-user', JSON.stringify(
                        {userId: responseInfo.data.userId,
                        username: data.username}
                    ))
                    props.setUserInfo(prev=>({
                        ...prev, 
                        userId: responseInfo.data.userId,
                        username: data.username
                    }))
                    props.setLoginOpen("")
                }
                
            })
            .catch((err)=>{
                console.log(err)
            })
    };

    return (
        <div className="login-page" style={{display:props.loginOpen=="login"?"flex":"none"}}>
            
            <p className="login-page-title">Login</p>

            <p className="login-page-minortext">Don't Have an account yet? 
                <span className="login-page-signuplink" onClick={()=>props.setLoginOpen('signup')}>Sign Up</span>
            </p>


            {/*Formik Form below */}
            <Formik initialValues={initialValues} onSubmit={logInSubmit} validationSchema={validationSchema}>
                
                <Form className="signup-form">

                    <label className="signup-label">
                        Username
                        <ErrorMessage name="username" className="signup-errormsg" component="span"/>
                    </label>
                    <Field id="username" name="username" placeholder="myusername123" className="signup-field"/>
                    

                    <label className="signup-label">
                        Password
                        <ErrorMessage name="password" className="signup-errormsg" component="span"/>
                    </label>
                    <Field type="password" id="password" name="password" placeholder="Enter Password" className="signup-field"/>
                    

                    <div className="signup-checkbox-wrapper">
                        <Field type="checkbox" id="rememberme" name="rememberme" className="signup-field-remember"/>
                        <label className="signup-checkbox-text">Remember me</label>
                    </div>
                    

                    <button type="submit" className="signup-submitbtn">LOGIN</button>

                </Form>
            </Formik>

            {/*'or login with' */}
            <div className="signup-orsignupwith">
                <hr className="signup-separator"></hr>
                <span className="signup-separator-text">
                    or login with
                </span>
                <hr className="signup-separator"></hr>
            </div>


            <div className="signup-googlefb-btns">
                <button className="signup-googlebtn">
                    <img src={googleLogo} className="signup-googlelogo"/>
                    <p className="signup-googlefb-text">Google</p>
                </button>

                <button className="signup-fbbtn">
                    <img src={facebookLogo} className="signup-fblogo"/>
                    <p className="signup-googlefb-text">facebook</p>
                </button>

            </div>



        </div>
    )
}