import React from "react";

import lp3Img from "../../images/landingpage/landingfeatures-new.png";

import notesImg from "../../images/landingpage/landing-notes.png";
import notesImgFocus from "../../images/landingpage/landing-notes-focus.png";

import codeImg from "../../images/landingpage/landing-code.png";
import codeImgFocus from "../../images/landingpage/landing-code-focus.png";

import chatbotImg from "../../images/landingpage/landing-chatbot.png";
import chatbotImgFocus from "../../images/landingpage/landing-chatbot-focus.png";

import layoutImg from "../../images/landingpage/landing-layout.png";
import layoutImgFocus from "../../images/landingpage/landing-layout-focus.png";

import calculatorImg from "../../images/landingpage/landing-calculator.png";
import calculatorImgFocus from "../../images/landingpage/landing-calculator-focus.png";

export default function LpFeatures() {

    React.useEffect(() => {
        const preloadImages = [notesImg, notesImgFocus, codeImg, codeImgFocus, chatbotImg, chatbotImgFocus, layoutImg, layoutImgFocus, calculatorImg, calculatorImgFocus];
      
        preloadImages.forEach((image) => {
          new Image().src = image;
        });
      }, []);

    const [focusIndex, setFocusIndex] = React.useState(0);
    const [hovered, setHovered] = React.useState(false);

    /*State cycling order */
    const focusOrder = ["notes", "code", "chatbot", "layout", "calculator"]
    const currFocus = focusOrder[focusIndex];

    React.useEffect(
        ()=>{
            let interval;
            if (!(hovered)) {
                interval = setInterval(()=>{
                    setFocusIndex(prev=>(prev+1)%focusOrder.length)
                }, 3500)
            }
            else {
                interval = setInterval(()=>{
                    setHovered(false)
                }, 20000)
            }
            return ()=>clearInterval(interval)
        }, 
    [hovered]);

    function handleHover(compIndex) {

        //stop automatic cycling by setting hovered to true
        setHovered(true);

        //set the current focused comp to that which was hovered
        setFocusIndex(compIndex);
    }

    return (
        <div className="lp-features">

            <p className="lp-features-text">
                Plenty of Built-in Features
            </p>




                {/*Relative position containing back img and interactive images */}
                <div className="lp-features-img-container" >

                    {/*bkg img*/}
                    <img src={lp3Img} className="lp-features-img" />


                    {/*interactable images overlaying bkg */}
                    <div className="lp-features-innerimg1-cont" onMouseEnter={()=>handleHover(0)}>
                        <img src={currFocus=="notes"?notesImgFocus:notesImg}className="lp-features-innerimg1" />
                    </div>

                    <div className="lp-features-innerimg2-cont" onMouseEnter={()=>handleHover(1)}>
                        <img src={currFocus=="code"? codeImgFocus:codeImg} className="lp-features-innerimg2" />
                    </div>

                    <div className="lp-features-innerimg3-cont" onMouseEnter={()=>handleHover(2)}>
                        <img src={currFocus=="chatbot"?chatbotImgFocus:chatbotImg} className='lp-features-innerimg3'/>
                    </div>

                    <div className="lp-features-innerimg4-cont" onMouseEnter={()=>handleHover(3)}>
                        <img src={currFocus=="layout"?layoutImgFocus:layoutImg} className="lp-features-innerimg4" />
                    </div>

                    <div className="lp-features-innerimg5-cont" onMouseEnter={()=>handleHover(4)}>
                        <img src={currFocus=="calculator"?calculatorImgFocus:calculatorImg} className="lp-features-innerimg5" />
                    </div>


                    {/*focus info for each highlighted image */}
                    <div className="lp-features-focusinfo1-cont" style={{display:currFocus=="notes"?"block":"none"}}>
                        <p className="lp-features-focusinfo-text">Access your course notes anywhere and anytime.</p>
                    </div>

                    <div className="lp-features-focusinfo2-cont" style={{display:currFocus=="code"?"block":"none"}}>
                        <p className="lp-features-focusinfo-text">Streamline your coding journey by using our integrated code compiler.</p>
                    </div>

                    <div className="lp-features-focusinfo3-cont" style={{display:currFocus=="chatbot"?"block":"none"}}>
                        <p className="lp-features-focusinfo-text">Get instant guidance from our chatgpt-powered AI chatbot.</p>
                    </div>

                    <div className="lp-features-focusinfo4-cont" style={{display:currFocus=="layout"?"block":"none"}}>
                        <p className="lp-features-focusinfo-text">Tailor your viewing layout to improve efficiency.</p>
                    </div>

                    <div className="lp-features-focusinfo5-cont" style={{display:currFocus=="calculator"?"block":"none"}}>
                        <p className="lp-features-focusinfo-text">Perform quick and easy calculations using our calculator widget.</p>
                    </div>

                </div>


                

            </div>
        
    )
}