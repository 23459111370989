import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import * as Yup from 'yup';

import facebookLogo from "../images/facebook-logo.png";
import googleLogo from "../images/google-logo.png";

export default function Signup(props) {

    const initialValues = {
        email: "",
        password: "",
        passwordconfirm: "",
        rememberme: false
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('*Invalid Email').required('*Email is required'),
        password: Yup.string().min(7, '*Must contain at least 7 characters').required('*Password is required'),
        passwordconfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], '*Passwords must match')
          .required('*Confirmation required'),
    });

    const signUpSubmit = (data) => {
        axios.post("https://api.skillsagelearn.com/user", {
            username:data.username,
            password:data.password
        })
            .then((response)=>{
                const responseData = response.data;
                if (responseData.status=='error') {
                    if (responseData.error.details && 
                        responseData.error.details.code && 
                        responseData.error.details.code=='ER_DUP_ENTRY') {
                        throw new Error("Username taken!")
                    }
                    else {
                        throw new Error(responseData.error.message)
                    }

                }
                else if (!responseData.data || !responseData.data.affectedRows) {
                    throw new Error("User data was not entered")
                }
                else {
                    console.log(`Account created with username ${data.username}`)
                }
            })
            .catch((err)=>{
                console.log(err)

            })
    };

    return (
        <div className="login-page" style={{display:props.loginOpen=="signup"?"flex":"none"}}>
            
            <p className="login-page-title">Sign Up</p>
            <p className="login-page-minortext">Have an account already? 
                <span className="login-page-signuplink" onClick={()=>props.setLoginOpen('login')}>Log in</span>
            </p>


            {/*Formik Form below */}
            <Formik initialValues={initialValues} onSubmit={signUpSubmit} validationSchema={validationSchema}>
                
                <Form className="signup-form">

                    <label className="signup-label">
                        Email Address
                        <ErrorMessage name="email" className="signup-errormsg" component="span"/>
                    </label>
                    <Field type="email" id="signup-email" name="email" placeholder="me@example.com" className="signup-field"/>
                    

                    <label className="signup-label">
                        Password
                        <ErrorMessage name="password" className="signup-errormsg" component="span"/>
                    </label>
                    <Field type="password" id="signup-password" name="password" placeholder="Enter Password" className="signup-field"/>

                    <label className="signup-label">
                        Confirm Password
                        <ErrorMessage name="passwordconfirm" className="signup-errormsg" component="span"/>
                    </label>
                    <Field type="password" id="signup-passwordconfirm" name="passwordconfirm" placeholder="Confirm Password" className="signup-field"/>         

                    <div className="signup-checkbox-wrapper">
                        <Field type="checkbox" id="signup-rememberme" name="rememberme" className="signup-field-remember"/>
                        <label className="signup-checkbox-text">Remember me</label>
                    </div> 
                    
 
                    <button type="submit" className="signup-submitbtn">SIGN UP</button>

                </Form>
            </Formik>

            {/*'or login with' */}
            <div className="signup-orsignupwith">
                <hr className="signup-separator"></hr>
                <span className="signup-separator-text">
                    or sign up with
                </span>
                <hr className="signup-separator"></hr>
            </div>


            <div className="signup-googlefb-btns">
                <button className="signup-googlebtn">
                    <img src={googleLogo} className="signup-googlelogo"/>
                    <p className="signup-googlefb-text">Google</p>
                </button>

                <button className="signup-fbbtn">
                    <img src={facebookLogo} className="signup-fblogo"/>
                    <p className="signup-googlefb-text">facebook</p>
                </button>

            </div>



        </div>
    )
}