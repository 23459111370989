import React from "react";


export default function Footer() {

    return (
        <footer className="footer">
            <div className="footer-row">
                <div className="footer-about-col">
                    <p className="footer-title">About</p>
                    <p>SkillSage: Your go-to hub for seamless self-learning. 
                        Discover curated online courses with community reviews, 
                        watch, take notes, and practice coding—all in one place. 
                        Elevate your skills effortlessly.</p>
                </div>
                <div className="footer-quicklinks-col">
                    <p className="footer-title">Quick Links</p>
                    <ul className="footer-quicklinks">
                        <li>About Us</li>
                        <li>Contact Us</li>
                        <li>Contribute</li>
                        <li>Sitemap</li>
                    </ul>
                </div>
            </div>
            <hr className="footer-break"/>
            <div className="footer-cont">
                <p className="footer-copyright">&#x24B8;2023 SkillSage, All right reserved.</p>
            </div>
        </footer>
    )
}