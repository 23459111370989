import React from "react";
import VideoSideNavCard from "./VideoSideNavCard";


export default function VideoSideNav(props) {

    
    const vidCards = props.courseVideos.map(
        (video, index) => (
            <VideoSideNavCard 
                expanded={props.expanded}
                courseId={props.courseId}
                videoThumbnail={video.videoThumbnail}
                videoName={video.videoName}
                videoId={video.videoId}
                videoIndex={index}
                videoCreator={video.videoCreator}
            />
        )
    )

    return (
        <div className={props.expanded?"videosidenav-container-expanded":"videosidenav-container-contracted"}>

            <div className="videosidenav">
                {vidCards}
            </div>
            
        </div>
    )
}