import React from "react";
import { EditorState, EditorView, basicSetup } from "@codemirror/basic-setup";
import { javascript } from "@codemirror/lang-javascript";


import Editor from "./Editor.js";

import useLocalStorage from "../hooks/useLocalStorage.js";



export default function CodeEditor(props) {


    //Create state for saving html/css/javascript code
    const [html, setHtml] = useLocalStorage('html', '<!--Add HTML here -->');
    const [css, setCss] = useLocalStorage('css', '/*Add CSS here*/');
    const [js, setJs] = useLocalStorage('js', '//Add javascript here');

    //const [srcDoc, setSrcDoc] = React.useState('');
    const [srcDoc, setSrcDoc] = React.useState(`
                                                <!DOCTYPE html>
                                                <html>
                                                <body>
                                                    <style>${css}</style>
                                                    ${html}
                                                    <script>${js}</script>
                                                </body>
                                                </html>
                                            `)

    //Create state for defining which of html/css/js is selected
    const [chosenTab, setChosenTab] = React.useState('html');



    //For consistently updating the srcDoc with most recent html/css/js
    /*
    React.useEffect(() => {
        const timeout = setTimeout(()=>{
            setSrcDoc(`
            <html>
                <body>${html}</body>
                <style>${css}</style>
                <script>${js}</script>
            </html>
        `
            )
        }, 250)
        return () => {clearTimeout(timeout)}
    }, [html, css, js]);
    */

    function runCode() {
        setSrcDoc(
            `
            <!DOCTYPE html>
            <html>     
            <body>
                <style>${css}</style>
                ${html}
                <script>${js}</script>
            </body>
            </html>
        `
        )
    }


    return (
        <div className="code-editor">

            <div className="ce-title-container">
                <div className="ce-tabs-container">
                    <div className={`ce-title-tab${chosenTab=='html'?'-clicked':''}`} onClick={()=>setChosenTab('html')}>HTML</div>
                    <div className={`ce-title-tab${chosenTab=='css'?'-clicked':''}`} onClick={()=>setChosenTab('css')}>CSS</div>
                    <div className={`ce-title-tab${chosenTab=='js'?'-clicked':''}`} onClick={()=>setChosenTab('js')}>JS</div>
                </div>
                <button className="ce-runcodebtn"onClick={()=>{runCode()}}>Run Code</button>
            </div>

            <div className="pane top-pane">
                
                <div className="editor-container">
                    {chosenTab==='html' && 
                    <Editor 
                        language='xml'
                        value={html}
                        onChange={setHtml}/>}
                    
                    {chosenTab==='css' && 
                    <Editor 
                        language='css'
                        value={css}
                        onChange={setCss}/>}

                    {chosenTab==='js' && 
                    <Editor 
                        language='javascript'
                        value={js}
                        onChange={setJs}/>}    
                    
                        
                </div>
            </div>

            <div className="pane bottom-pane">
                <iframe 
                title="output" 
                srcDoc={srcDoc}
                sandbox="allow-scripts"
                frameBorder="0"
                width="100%"
                height="100%"/>
            </div>
        </div>
    )
}