import React from "react"
import { createRoot }from "react-dom/client"

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import App from "./App.js";

import "./styles/body.css";
import "./styles/card.css";
import "./styles/footer.css";
import "./styles/homead.css";
import "./styles/navbar.css";
import "./styles/stars.css";
import "./styles/videogrid.css";
import "./styles/videoplayer.css";
import "./styles/codeeditor.css";
import "./styles/chatboxwidget.css";
import "./styles/courseinfo.css";
import "./styles/videosidenav.css";
import "./styles/vpwidget.css";
import "./styles/landingpage.css";
import "./styles/login.css";
import "./styles/homepage.css";


const root = document.querySelector('#root');

// Use createRoot to render your app
const rootElement = createRoot(root);

rootElement.render( <App />);
