import React from "react";
import {Link} from "react-router-dom";

export default function Card(props) {
    return (
        <Link 
            to= {`/CourseInfo/${props.courseId}`}
            state={{courseInfo:props}}
            className="course-link">
            <div className="card">
                <div className="card-thumbnail-container">
                    <img src={props.courseThumbnail} className="card-thumbnail" alt={props.title} />
                </div>
                
                <p className="course-name">
                        {props.courseName}
                </p>
                {/*For below the creator isn't created yet because playlists are confusing */}
                {/*<p className="course-creator">{props.creator}</p> */}
                <div className="card-row">
                    <img src={require('../images/star.png')} className="star-img" alt="Star" />
                    <span className="card-rating">4.5{/*props.rating*/}</span>
                    <span className="card-rating-numreviews">(123{/*props.num_reviews*/})</span>
                    <span className="card-dot"> &#xb7; </span>
                    <p className="course-cost">FREE {/*props.cost*/}</p>
                </div>
            </div>
        </Link>
    );
}