import React from "react";
import axios from "axios";

import VideoGrid from "../VideoGrid.js";


export default function LpCourses() {

    //which course category is hovered
    const [focusIndex, setFocusIndex] = React.useState(0);
    const courseCats = ["Python", "Web Development", "Algebra", "Calculus", "Statistics and Probability", "Differential Equations", "Biology"];
    const currCat = courseCats[focusIndex];


    //state for storing coursedata
    const [coursesData, setCourseData] = React.useState([]);
    React.useEffect(() => {
        axios.get(`https://api.skillsagelearn.com/course/bycategory?category=${currCat.replace(' ', '~')}`)
            .then((response) => {
                setCourseData(response.data.data.slice(0,12));
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [focusIndex]);

    //create course category buttons for grid
    const courseButtons = courseCats.map(
        (name, index) => 
            (
            <button 
                className={index==focusIndex?"lp-coursebutton-hover":"lp-coursebutton"}
                key={index}
                onMouseEnter={()=>setFocusIndex(index)}>
                {name}
            </button>
            )
    );

    return (
        <div className="lp-courses">

            <p className="lp-courses-text">
                Checkout Popular Courses
            </p>

            <div className="lp-coursebuttons-container">
                {courseButtons}
            </div>

            <VideoGrid coursesData={coursesData}/>

        </div>
    )
}