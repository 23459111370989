import React from "react";

export default function BallSim() {

    const canvasRef = React.useRef(null);
    const canvasContRef = React.useRef(null);
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

    React.useEffect(() => {
        const canvas = canvasRef.current;
        const canvasCont = canvasContRef.current;

        const c = canvas.getContext('2d');

        const listener = window.addEventListener('resize', init);


            //rotates velocity {x, y} by angle counter-clockwise
    function rotate2d(velocity, angle) {
        return {
            x: velocity.x*Math.cos(angle)-velocity.y*Math.sin(angle),
            y: velocity.x*Math.sin(angle)+velocity.y*Math.cos(angle)
        }
    };

    function Circle(x,y, radius, mass, velocity, color) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.mass = mass;
        this.velocity = velocity;
        this.color = color;

        this.draw = ()=>{
            c.strokeStyle = this.color=="white"?"black":this.color;
            c.beginPath();
            c.arc(this.x, this.y, this.radius, 0, 2*Math.PI, false);
            c.save();
            c.globalAlpha = 0.3;
            c.fillStyle = this.color;
            c.fill();
            c.restore();
            c.stroke();
        }

        this.update = (circleArray, comebackBoost)=>{
            this.draw();


            for (let i=0; i<circleArray.length; i++) {
                if (this==circleArray[i]) continue;
                
                const otherCircle = circleArray[i];

                //check if colliding
                if ((otherCircle.x-this.x)**2+(otherCircle.y-this.y)**2<=(otherCircle.radius+this.radius)**2) {
                    const res = {
                        x: this.velocity.x - otherCircle.velocity.x,
                        y: this.velocity.y - otherCircle.velocity.y
                    };
                    

                    //to avoid overlap
                    if (res.x * (otherCircle.x - this.x) + res.y * (otherCircle.y - this.y) >= 0) {
                        
                        if ((this.velocity.x**2+this.velocity.y**2)*this.mass > (otherCircle.velocity.x**2+otherCircle.velocity.y**2)*otherCircle.mass) {
                            otherCircle.color = this.color;
                        }
                        else {
                            this.color = otherCircle.color;
                        }

                        const m1 = this.mass;
                        const m2 = otherCircle.mass;

                        const angle = -Math.atan2(otherCircle.y-this.y, otherCircle.x-this.x);

                        const u1 = rotate2d(this.velocity, angle);
                        const u2 = rotate2d(otherCircle.velocity, angle)

                        const v1 = {
                            x: (u1.x*(m1-m2)+u2.x*2*m2)/(m1+m2),
                            y: u1.y
                        }
                        const v2 = {
                            x:(u2.x*(m1-m2)+u1.x*2*m2)/(m1+m2),
                            y:u2.y
                        }

                        this.velocity = rotate2d(v1, -angle);
                        otherCircle.velocity = rotate2d(v2, -angle);

                    }
                }
            }


            if ((this.x+this.radius>=canvasCont.clientWidth) || (this.x-this.radius<=0)) {
                this.velocity.x *= -1;
            }
            if ((this.y+this.radius>=canvasCont.clientHeight) || (this.y-this.radius<=0)) {
                this.velocity.y *= -1
            }

            //comeback boost
            if (circleArray.filter((circle)=>(circle.color==this.color)).length==1 && !comebackBoost[this.color]) {
                comebackBoost[this.color] = true;
                const newSpeed = 10;
                const newSpeedX = Math.random()<0.5?(Math.random()*newSpeed**2)**0.5: -1*(Math.random()*newSpeed**2)**0.5;
                const newSpeedY = Math.random()>0.5?(newSpeed**2-newSpeedX**2)**0.5:-1*(newSpeed**2-newSpeedX**2)**0.5;

                this.velocity = {
                    x: newSpeedX,
                    y: newSpeedY
                }
            }


            this.x += this.velocity.x;
            this.y += this.velocity.y;
        }
    }

    function Legend(x,y,title, fontsize, spacing, colors) {
        this.title = title;
        this.fontsize = fontsize;
        this.colors = colors;
        this.totalCount = 0;
        for (let i=0; i<colors.length;i++) {
            this.totalCount += colors[i].count
        }
        this.x = x;
        this.y = y;
        this.spacing = spacing;

        this.draw = () => {
            const rectHeight = this.fontsize + this.spacing*(this.colors.length+3)+ this.fontsize*this.colors.length;
            c.fillStyle = 'rgba(0,0,0, 0.4';
            c.fillRect(this.x-this.fontsize, this.y-this.fontsize-this.spacing, canvas.width/2.3, rectHeight)
            c.font = `${this.fontsize}px Arial`;
            c.fillStyle = 'black';
            c.fillText(title, this.x, this.y);


            this.colors = this.colors.sort((a,b)=>(a.count>b.count?-1:1));
            for (let i=0; i<this.colors.length; i++) {
                c.font = `${this.fontsize}px`;
                c.fillStyle = this.colors[i].name;
                c.fillText(`${this.colors[i].name}    ${Math.round(this.colors[i].count*100.0/this.totalCount)}%    ${this.colors[i].comeback?"inactive":"ACTIVE"}`, this.x, this.y+(this.fontsize+this.spacing)*(i+1));
            }
        };

        this.update = (circleArray, comebackBoost) => {
            for (let i=0; i<this.colors.length; i++) {
                const color = this.colors[i].name;
                
                this.colors[i].count= circleArray.filter((circle)=>(circle.color==color)).length;
                this.colors[i].comeback = comebackBoost[color];
            };
            this.draw();
        };  
    }


    let circleArray, comebackBoost, legend;
    function init() {

        canvas.width = canvasCont.clientWidth;
        canvas.height = canvasCont.clientHeight;

        setWindowHeight(window.innerHeight);

        circleArray = [];

        const colors = ['red', 'green', 'orange', 'blue', 'black', 'white'];
        //const colors = ['#B225D9', '#0597F2', '#05F26C', '#F2A007', '#F2220F', 'black', 'white']

        comebackBoost = {};
        colors.forEach((color)=>{
            comebackBoost[color] = false
        });

        let radius = (canvasCont.clientWidth*canvasCont.clientHeight/5600)**0.5;
        for (let i=0; i<(Math.round(canvasCont.clientWidth*canvasCont.clientHeight*1.0/(25*radius**2))); i++) {

            const mass = radius**2;
            const speed = 1.5;
            const speedX = Math.random()<0.5?(Math.random()*speed**2)**0.5: -1*(Math.random()*speed**2)**0.5;
            const speedY = Math.random()>0.5?(speed**2-speedX**2)**0.5:-1*(speed**2-speedX**2)**0.5;

            const velocity = {
                x:speedX,
                y:speedY
            }
            const color = colors[i%colors.length];

            let x = radius + Math.random()*(canvasCont.clientWidth-2*radius);
            let y = radius + Math.random()*(canvasCont.clientHeight-2*radius);
            for (let j=0; j<circleArray.length; j++) {
                if ((circleArray[j].x-x)**2 + (circleArray[j].y-y)**2 <= (circleArray[j].radius+radius)**2) {
                    x = radius + Math.random()*(canvasCont.clientWidth-2*radius);
                    y = radius + Math.random()*(canvasCont.clientHeight-2*radius);
                    j = -1;
                    continue
                }
            }




            circleArray.push(new Circle(x,y,radius, mass, velocity,color))
        }


        //add legend
        const legendColors = colors.map((color)=>(
            {
                name: color,
                count: circleArray.filter((circle)=>(circle.color==color)).length,
                comeback: false
            }
        ));
        legend = new Legend(canvas.width/10,canvas.width/10,'Color     %      Boost?', canvas.width/30, canvas.width/45, legendColors);
        legend.draw();

        for (let i=0; i<circleArray.length; i++) {
            circleArray[i].draw()
        }
    }

    let gameEnd = false;
    function animate() {
        c.clearRect(0,0,canvasCont.clientWidth, canvasCont.clientHeight);
        requestAnimationFrame(animate);

        const colorsSet = new Set(circleArray.map(circle=>circle.color));

        if (colorsSet.size==1 && !gameEnd) {
            gameEnd = true;
            circleArray.forEach((circle)=>{
                circle.velocity.x /= 2;
                circle.velocity.y /= 2;
            })
            setTimeout(()=>{gameEnd=false;init();}, 3000);
        }

        for (let i=0; i<circleArray.length; i++) {
            circleArray[i].update(circleArray, comebackBoost);
        }
        legend.update(circleArray, comebackBoost);
    }

    init();
    animate();

        return () => {
            canvasCont.removeEventListener('resize', listener);
        };
    }, []);
    

    return (
        <div ref={canvasContRef} style={{height:windowHeight*0.95, border:"1px solid black", margin: "50px 20px 50px", }}>
            <canvas ref={canvasRef}></canvas>
        </div>
    )
}