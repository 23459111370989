import React from "react"
import emptyStar from "../images/emptyStar.png"
import filledStar from "../images/star3.png"

export default function Stars(props) {
    const filledPercent = 100*((props.rating+0.015)/5.0)
    return (
        <div className="stars">
            <div className="empty-stars">
                <img src={emptyStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="empty-star"></img>
                <img src={emptyStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="empty-star"></img>
                <img src={emptyStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="empty-star"></img>
                <img src={emptyStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="empty-star"></img>
                <img src={emptyStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="empty-star"></img>
            </div>
            <div className="filled-stars" style={{clipPath: `polygon(0% 0%, ${filledPercent}% 0%, ${filledPercent}% 100%, 0% 100%)`}}>
                <img src={filledStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="filled-star"></img>
                <img src={filledStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="filled-star"></img>
                <img src={filledStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="filled-star"></img>
                <img src={filledStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="filled-star"></img>
                <img src={filledStar} style={{height:props.starSize, width:props.starSize}} draggable="false" className="filled-star"></img>
            </div>
        </div>
    )
}