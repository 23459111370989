import React from "react";
import {useParams, useLocation} from "react-router-dom";
import SplitPane from "react-split-pane";

import DndWidget from "./DndWidget.js";
import CodeEditor from "./CodeEditor.js";
import NotesWrapper from "./NotesWrapper.js";
import VpWidget from "./VpWidget.js";
import ChatBoxWidget from "./ChatBoxWidget.js";
import VpSidebar from "./VpSidebar.js";




export default function VideoPlayer(props) {

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
      }, []);

    //get courseId and videoID from link parameters
    const params = useParams();
    const location = useLocation();
    const courseId = params.courseId;
    const videoId = params.videoId;




    //state to control which widgets are in left and right of SplitPane
    const [leftComps, setLeftComps] = React.useState(["vp"])
    const [rightComps, setRightComps] = React.useState([])

    //setup object that maps comp keys to the comps they display
    const comps = {
        "notes": <DndWidget 
                element={<NotesWrapper userInfo={props.userInfo}/>}
                title="Notes"
                handleOnDrag={(e)=>handleOnDrag(e,"notes")}
            />,
        "codeeditor": <DndWidget
            element={<><CodeEditor /></>}
            title="Code Editor"
            handleOnDrag={(e)=>handleOnDrag(e,"codeeditor")}
            />,
        "vp": <DndWidget
                element={<><VpWidget {...location.state.videoInfo}/></>}
                title=""
                handleOnDrag={(e)=>handleOnDrag(e,"vp")}
            />,
        "chatbot": <DndWidget
                element={<ChatBoxWidget />} 
                title=""
                handleOnDrag={(e)=>handleOnDrag(e,"chatbot")}
            />
    }
    
    /*Functions to handle adding and removing comps*/
    function handleRemove(comp) {

        //remove null values 
        leftComps.filter(val=>val);
        rightComps.filter(val=>val)


        if (leftComps.includes(comp)) {
            setLeftComps((prev) => prev.filter(val => (val!==comp)))
        }
        if (rightComps.includes(comp)) {
            setRightComps((prev) => prev.filter(val => (val!==comp)))
        }
    };

    function handleAdd(comp) {

        //remove null values 
        leftComps.filter(val=>val);
        rightComps.filter(val=>val);


        if (!(leftComps.includes(comp) || rightComps.includes(comp))) {
            if (leftComps.length<=rightComps.length) {
                setLeftComps((prev)=>[...prev, comp])
            }
            else {
                setRightComps((prev)=>[...prev, comp])
            }
        }
    };



    /*Functions to handle dragging and dropping */
    function handleOnDrag(e, comp) {
        e.dataTransfer.setData("comp", comp);
    }

    function handleOnDrop(e, side) {
        const comp = String(e.dataTransfer.getData("comp"));
        if (side==="left") {
            if (!(leftComps.includes(comp))) {
                setLeftComps(prev => [...prev, comp])
            }

            setRightComps(prev => prev.filter(element => (element !== comp)))

        }
        else {
            if (!(rightComps.includes(comp))) {
                setRightComps(prev => [...prev, comp])
            }
            
            setLeftComps(prev => prev.filter(element => (element !== comp)))
        }
    }


    /* Create comp elements */
    const leftCompsElements = leftComps.map((compNum) => (
        comps[compNum]
    ));
    const rightCompsElements = rightComps.map((compNum) => (
        comps[compNum]
    ));


    return (    

        <div className="vp-page">

            <VpSidebar 
                handleAdd={handleAdd}
                handleRemove={handleRemove}
                allComps={leftComps+rightComps}
            />
            
            {/*splitpane */}
            <SplitPane 
            split="vertical" 
            defaultSize={window.innerWidth*3.0/4}
            style={{position:"static"}}>

                {/*left side */}
                <div 
                    className="vp-sidebar-left"
                    onDrop={(e) => handleOnDrop(e, "left")}
                    onDragOver={(e)=>e.preventDefault()}>
                    {leftCompsElements}
                </div>

                {/*right side */}
                <div 
                    className="vp-sidebar-right"
                    onDrop={(e) => handleOnDrop(e, "right")}
                    onDragOver={(e)=>e.preventDefault()}>
                    {rightCompsElements}
                </div>
            </SplitPane>

        </div>
        

    )
}