import React from "react";
import VideoGrid from "../VideoGrid.js";

import axios from "axios";

export default function FavCourses(props) {
    const [coursesData, setCoursesData] = React.useState([]);

    //Get favorite courses from database
    React.useEffect(()=>{
        axios.get(`https://api.skillsagelearn.com/favoritecourse/byuser?userId=${props.userInfo.userId}`)
            .then((response)=>{
                const responseData = response.data;
                if (responseData.status=='error') {
                    throw new Error(responseData.error.message)
                }
                else {
                    setCoursesData(responseData.data)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
    }, []);
    return (
        <div className="favcourses">
            <p className="favcourses-title">My Courses</p>
            <VideoGrid coursesData={coursesData} />
        </div>
    )
}