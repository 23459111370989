import React from "react";
import Card from "./Card.js";


export default function VideoGrid(props) {


    let courseCards = props.coursesData.map(
        props => <Card 
                    key={props.courseId}
                    {...props}
                />
    );
    return(
        <div className="video-grid">
            {courseCards}
        </div>
    ) 
}