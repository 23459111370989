import React from "react";
import {Link} from "react-router-dom";

export default function VideoSideNavCard(props) {
    return (
        <Link 
            to={`/VideoPlayer/${props.courseId}/${props.videoId}`} 
            state={{videoInfo: props}}
            style={{textDecoration:"None"}}>
            <div className={props.expanded?"videosidenav-card-expanded":"videosidenav-card-contracted"}>
                <div className="videosidenav-cardnum">{props.videoIndex*1.0+1}</div>
                <img src={props.videoThumbnail} className="videosidenav-cardthumb"/>

                <div className="videosidenav-card-titlecreator" style={{display:props.expanded?"flex":"None", flexDirection:"column"}}>
                    <p className="videosidenav-card-title">
                        {props.videoName}
                    </p>

                    <p className="videosidenav-card-creator">
                        {props.videoCreator}
                    </p>
                </div>
            </div>
        </Link>
    )
}