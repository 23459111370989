import axios from "axios";
import React from "react";
import Notes from "./Notes.js";

import zlib from "react-zlib-js/index.js";
import buffer from "react-zlib-js/buffer.js";

import docIcon from "../../images/icons/doc-icon.png";

export default function NotesWrapper(props) {

    const [editorOpen, setEditorOpen] = React.useState(false);
    const [initialValue, setInitialValue] = React.useState("");
    const [notesNames, setNotesNames] = React.useState([]);

    const handleSelectorClick =(notesId)=>{
        axios.get(`https://api.skillsagelearn.com/notes/bynotesid?notesId=${notesId}`)
            .then((response)=>{
                if (response.data.status=="error") {
                    throw new Error(response.data.error.message)
                }
                else {
                    console.log(response.data.data[0])
                    let notesContent = response.data.data[0].notesContent;
                    zlib.unzip(new buffer(notesContent, 'base64'), (error, result) => {
                        if (error) {throw error}
                        setInitialValue(result.toString());
                    });
                    setEditorOpen(true);
                }
            })
            .catch((err)=>{
                console.log(err)
            })
    };

    const notesElements = notesNames.map((notesObj)=>(
        <>
        <div 
            className="notes-selector-row" 
            key={notesObj.notesId}
            onClick={()=>handleSelectorClick(notesObj.notesId)}>
            <img src={docIcon} className="notes-docicon"/>
            <p className="notes-selector-name">{notesObj.notesName}</p>
            <p className="notes-selector-date">{notesObj.lastOpenedDate}</p>
        </div>
        <hr className="notes-selector-divider"/>
        </>)
    );


    React.useEffect(()=>{
        if (props.userInfo && props.userInfo.userId) {
            axios.get(`https://api.skillsagelearn.com/notes/getnames?userId=${props.userInfo.userId}`)
                .then((response)=>{
                    const responseData = response.data;
                    if (responseData.status=="error") {
                        throw new Error(responseData.error.message)
                    }
                    else {
                        const notesData = responseData.data.map((notesObj)=>(
                            {...notesObj, lastOpenedDate: formatDate(notesObj.lastOpenedDate)}
                        ))
                        setNotesNames(notesData)
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
        }
        else {
            setEditorOpen(false)
        }
    }, [])

    return (
        <div className="noteswrapper">
            {editorOpen?
            <Notes initialValue={initialValue}/>
            :
            <div className="notes-selector">
                <div className="notes-selector-headers">
                    <p>Name</p>
                    <p>Last Edit</p>
                </div>
                <hr style={{margin:"0"}}/>
                {notesElements}
            </div>
            }
            
        </div>)
}


function formatDate(dateString) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${month}/${day}/${year}`;
}