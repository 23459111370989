import React from "react";

import LpIntro from "./LpIntro.js";
import LpFeatures from "./LpFeatures.js";
import LpReviews from "./LpReviews.js";
import LpCourses from "./LpCourses.js";
import LpGroups from "./LpGroups.js";



export default function LandingPage() {
    
    return (
        <div className="lp-page">
            
            <LpIntro />
            <LpFeatures />
            <LpReviews />
            <LpCourses />
            <LpGroups />
            
        </div>
    )
}