import React from "react";


export default function VpWidget(props) {
    console.log(props);
    return (
        <main className="vp-wrapper">
            <div className="videoWrapper">
                <iframe width="560" height="315" src={`https://www.youtube.com/embed/${props.videoId}`} frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>

            <div className="video-title-cont">
                <p className="video-title">{props.videoName}</p>
                <a className="video-creator" href={`https://www.youtube.com/${props.videoCreator.replace(/\s+/g, '')}`} target="_blank">{props.videoCreator}</a>
            </div>
        </main>
    )
}