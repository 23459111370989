import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";


import Navbar from "./components/Navbar.js";
import HomeAd from "./components/HomeAd.js";
import VideoGrid from "./components/VideoGrid.js";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer.js";
import Footer from "./components/Footer.js";
import CourseInfo from "./components/CourseInfo/CourseInfo.js";
import Login from "./components/Login.js";
import Signup from "./components/Signup.js";
import HomePage from "./components/HomePage/HomePage.js";

//testing
import LandingPage from "./components/LandingPage/LandingPage.js";
import BallSim from "./components/BallSim.js";
import OmniFlap from "./components/OmniFlap.js";



export default function App() {
    const [userInfo, setUserInfo] = React.useState({
                                            userId:null,
                                            username: null
                                    });

    const [loginOpen, setLoginOpen] = React.useState("");

    React.useEffect(() => {
        if (loginOpen=="signup" || loginOpen=="login") {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        }
        }
    }, [loginOpen]);
    React.useEffect(()=>{
        if (userInfo.userId==null) {
            try {
                const localUserInfo = JSON.parse(localStorage.getItem('skillsage-user'));
                if (localUserInfo!==null) {
                    setUserInfo({
                        userId: localUserInfo.userId || null,
                        username: localUserInfo.username || null
                    });
                }

            }
            catch (err) {
                console.log(err);
            }
        };
    }, [])
    
    return(
        <BrowserRouter >
            <Navbar 
                userInfo={userInfo} 
                setUserInfo={setUserInfo}
                loginOpen={loginOpen}
                setLoginOpen={setLoginOpen}/>

            <div onClick={()=>setLoginOpen("")}className="login-overlay" style={{display:loginOpen==""?"none":"block"}}></div>
            <Signup 
                loginOpen={loginOpen} 
                setLoginOpen={setLoginOpen} 
                userInfo={userInfo}
                setUserInfo={setUserInfo}/>
            <Login 
                loginOpen={loginOpen} 
                setLoginOpen={setLoginOpen} 
                userInfo={userInfo}
                setUserInfo={setUserInfo}/>
            
            <Routes>
                <Route path="/" element={
                                    <div className="App">
                                        {userInfo.userId==null?
                                        <LandingPage />
                                        :
                                        <HomePage 
                                            userInfo={userInfo}
                                            setUserInfo={setUserInfo}
                                        />
                                        }
                                        
                                    </div>}
                />
                
                <Route path="/CourseInfo/:courseId" 
                    element={<CourseInfo />} 
                />
                <Route path="/VideoPlayer/:courseId/:videoId" 
                    element={
                        <VideoPlayer userInfo={userInfo}/>
                    }
                />

                <Route path="/testing" element={
                            <Layout><BallSim /></Layout>
                            } />
                <Route path="/omniflap" element={<Layout><OmniFlap/></Layout>} />
                <Route path="/login" element={
                    <Login 
                        setUserInfo={setUserInfo}
                        userInfo = {userInfo}
                    />} 
                />
                <Route path="/signup" element={<Signup setUserInfo={setUserInfo}/>} />
            </Routes>
            <Footer />
        </BrowserRouter>

    );
} 


function Layout({ children }) {
    return (
      <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        {/* Navbar */}
        {/* You can replace the Navbar component with a placeholder or omit it entirely */}
        {/* <Navbar /> */}
  
        {/* Main content */}
        <div style={{ flex: 1 }}>
          {children}
        </div>
  
        {/* Footer */}
        {/* You can replace the Footer component with a placeholder or omit it entirely */}
        {/* <Footer /> */}
      </div>
    );
  }