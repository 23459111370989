import React, {useState} from "react";
import TextareaAutosize from 'react-textarea-autosize';
import AsyncOpenAI from "openai";

import chatbotImg from "../../images/chatbot.png";

//Function gets gpt response given an input message
const gptResponseProm = (input_messages) => {
    return new Promise(async (resolve, reject) => {
        try {
            const openai = new AsyncOpenAI({
                apiKey: "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",
                dangerouslyAllowBrowser: true
              });

            const completion = await openai.chat.completions.create({
                messages: input_messages,
                model: "gpt-3.5-turbo-1106"
              })    
            

            resolve(completion.choices[0].message.content)
        }
        catch (error) {
            console.log("error in gptResponseProm()", error);
            reject(error)
        }
    })
};

export default function ChatBoxWidget() {
    //state to control opening and closing of chat box
    const [openChat, setOpenChat] = React.useState(false);

    //Create state to record messages and new message for input
    const [messages, setMessages] = useState([]);
    const [newInput, setNewInput] = useState("");


    const handleSubmit = async () => {
        if (newInput.trim() !== "") {
            setMessages((prev) => [...prev, { role: "user", content: newInput }]);
            setNewInput(prev=>"");

            try {
                gptResponseProm([...messages, { role: "user", content: newInput }])
                    .then((gptResponse) => {setMessages(prev => [...prev, {role:"assistant", content: gptResponse}])})
                    .catch((error) => console.log("Error in OpenAiTest", error));
            }
            catch {
                    console.log('gpt error')
            }
        }
    }

    const [textareaHeight, setTextAreaHeight] = useState('30px')
    const handleInputChange = (e) => {
        const textarea = e.target;

        setTextAreaHeight(0);
        setTextAreaHeight(`${textarea.scrollHeight}px`)

        setNewInput(textarea.value)
    }

    //Convert messages into styled html items
    const messagesElements = messages.map((message, index) => (
        <div 
            className={message.role==="user"?"chat-user-msg":"chat-ai-msg"}
            key={index}>
            {message.role==="assistant" && <img src={chatbotImg} className="chat-botimg"/>}
            {message.content}
        </div>
    ));
    return (
        <div className="chatbox">

            {/*Chatbox Header */}
            <div className="chat-header">
                <img src={chatbotImg} className="chat-bot-headerimg"/>
                <p className="chatheader-text">Ask ChatGPT</p>
            </div>

            {/*Chat Previous Messages*/}
            <div className="chat-messages-container">
                {messagesElements}
            </div>


            <hr style={{color:"white", opacity:"1.0", border:"3px solid white", width:"90%", alignSelf:"center", borderRadius:"15px"}}/>


            {/*Submit Area*/}
            <div className="chat-input-cont">
                <TextareaAutosize
                    className="chat-input"
                    placeholder="Ask Chatgpt"
                    value={newInput}
                    onKeyDown = {(e) => {if (e.key=="Enter") {e.preventDefault();handleSubmit()}}}
                    maxRows={3}
                    onChange={(e) => setNewInput(e.target.value)}
                />
                <button 
                    className="chat-submit-btn"
                    onClick={()=>handleSubmit()}>
                    Submit
                </button>
            </div>

        </div>
    )
};