import React, { useState } from "react";
import ReactQuill from "react-quill";
import Latex from "react-latex";
import "react-quill/dist/quill.snow.css";
import { renderToString } from "react-dom/server";
import { InlineMath, BlockMath } from "react-latex";
import useLocalStorage from "../hooks/useLocalStorage";

export default function NotesTest(props) {

  const [value, setValue] = React.useState(props.initialValue);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        {
          color: ["red", "blue", "yellow"],
        },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleChange = (content) => {
    setValue(content);
  };

  const latexRenderer = (value) => {
    return renderToString(<Latex>{value}</Latex>);
  };

  return (
    <ReactQuill
      style={{width: "100%", height: "100%", border:"1px solid black", borderRadius:"8px"}}
      minSize="500px"
      theme="snow"
      value={value}
      onChange={handleChange}
      modules={modules}
      formats={formats}
      placeholder="Type something..."
      bounds=".app"
      // Add the following line to render LaTeX
      renderHTML={(text) => text.replace(/\$\$(.*?)\$\$/g, (_, latex) => latexRenderer(latex))}
    />
  );
}
