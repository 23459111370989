import React from "react";
import { Link } from "react-router-dom";
import blankProfilePic from "../images/icons/blankprofile.png";

export default function Navbar(props) {
    const handleSignOut = ()=>{
        props.setUserInfo({userId:null, username:null});
        props.setLoginOpen('');
        localStorage.setItem('skillsage-user', null);
    }
    return (
        <div className="navbar">


            <Link to="/" className="navbar-sitename">
                SkillSageLearn
            </Link>

            <div className="navbar-directs">
                <Link to="/Categories" className="navbar-categories">Categories</Link>
                {props.userInfo.username==null && <p className="navbar-login" onClick={()=>props.setLoginOpen('login')}>Log In</p>}
                {props.userInfo.username==null && <button className="navbar-signup" onClick={()=>props.setLoginOpen('signup')}>Sign Up</button>}

                {props.userInfo.username!=null && <p onClick={handleSignOut}className="navbar-login">Sign Out</p>}
                {props.userInfo.username!=null && <img src={blankProfilePic} className="navbar-profilepic" />}

                
            </div>

        </div>
        )
}



function NavbarOld() {
    return (
            <nav className="navbar navbar-expand-sm" id="myNav"> 

                    <Link to="/" className="navbar-brand mb-0 h1" id= "nav-brand"><strong className="nav-sitename">SkillSage</strong></Link>

                
                {/* <form className="d-flex" id="nav-search-form">
                    <input type="text" className="form-control me-2" id="search-input" placeholder="Search Courses"/>
                    <button type="submit" className="btn" id="search-button">Search</button>
                </form>*/}
                

                <button 
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                className="navbar-toggler"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                id="nav-hamburger">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav me-2">
                        <li className="nav-item active">
                            <Link to="/" className="nav-link">Home</Link>
                        </li>

                        <li className="nav-item dropdown" id="nav-cat-drop">
                            <a href="#" className="nav-link dropdown-toggle" 
                            role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">Categories</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li className="dropdown-item">
                                    <Link to="/testing" className="dropdown-item">Testing</Link>
                                </li>
                                <li className="dropdown-item">
                                    <Link to="/" className="dropdown-item">Category 2</Link>
                                </li>
                                <li href="#" className="dropdown-item">Category 3</li>
                            </ul>
                        </li>

                        <li className="nav-item active" id="nav-login">
                            <Link to="/login" className="nav-link">Log In</Link>
                        </li>
                        <li className="nav-item active" id="nav-signup-btn">
                            <Link to="/signup" className="nav-link" style={{textDecoration:"none"}}>Sign Up</Link>
                        </li>
                    </ul>
                </div>
            </nav>

    )
}