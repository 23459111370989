import React from "react";
import axios from "axios";
import zlib from "react-zlib-js/index.js";
import buffer from "react-zlib-js/buffer.js";

import { useParams, useLocation, Link } from "react-router-dom";

import VideoSideNav from "./VideoSideNav.js";
import Stars from "../Stars.js";
import { RectangleMarker } from "@codemirror/view";

export default function CourseInfo(props) {

    //state to control whether lecture videos are expanded
    const [expanded, setExpanded] = React.useState(true);
    const [description, setDescription] = React.useState("");


    const [courseVideos, setCourseVideos] = React.useState([]);
    const [courseInfo, setCourseInfo] = React.useState({});

    const {courseId} = useParams();
    
    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
      }, []);

    React.useEffect(()=>{
        if ('state' in location) {
            setCourseInfo(location.state.courseInfo || {})
        }
        else {
            axios.get(`https://api.skillsagelearn.com/course/info?courseId=${courseId}`)
                .then((response)=>{
                    if (response.data.status=='error') {
                        throw new Error(response.data.error)
                    }
                    else {
                        setCourseInfo(response.data.data[0]);
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
        }
        axios.get(`https://api.skillsagelearn.com/course/videos?courseId=${courseId}`)
            .then((response)=>{
                setCourseVideos(response.data.data);
            })
            .catch((err)=>{
                console.log(err)
            });
        
    }, []);

    React.useEffect(()=>{
        try {
            zlib.unzip(new buffer(courseVideos[0].videoDescription, 'base64'), (error, result) => {
                if (error) {throw error}
                setDescription(result.toString());
            });
        }
        catch (err) {
            console.log(err);
        }
    }, [courseVideos])

    

    let duration;
    if ('courseDuration' in props) {
        duration = Math.round(props.courseDuration/3600); 
    }
    else {
        duration = 1;
    }
    


    return (             
    <div className="courseinfo-page">


            {/*Basic info box and thumbnail box as row at top*/}
            <div className="courseinfo-top-container">

                {/*left of top box*/}
                <div className="courseinfo-topleft">

                    {/*Thumbnail box */}
                    <div className="courseinfo-thumbcontainer">
                        <img className="courseinfo-thumb" src={courseInfo.courseThumbnail || ""}/>
                    </div>  

                    <div className="courseinfo-namecreator-cont">
                        <p className="courseinfo-name">{courseInfo.courseName}</p>
                        <p className="courseinfo-creator">Created by <a style={{textDecoration:"none"}}href={`https://www.youtube.com/@${courseInfo.courseCreator}`}>{props.courseCreator}</a></p>
                        <Link 
                            to={`/VideoPlayer/${courseInfo.courseId}/${courseVideos[0]?courseVideos[0].videoId:""}`}
                            state={{videoInfo: courseVideos[0] || {}}}>
                            <button className="courseinfo-startcoursebtn">
                                Start &nbsp;Course &nbsp;Now&nbsp; &#x2192;
                            </button>
                        </Link>
                    </div>
                </div>

                {/*right of top box*/}
                <div className="courseinfo-topright">
                    <div className="courseinfo-basicinfo-cont">
                        <div className="courseinfo-reviews-row">
                            <Stars rating={4.7 /*props.rating */} starSize="45px"/>
                            <p className="courseinfo-numreviews">163 Reviews</p>
                        </div>

                        {/*Instructor information */}
                        <p className="courseinfo-basicinfo-row">
                            <span className="courseinfo-basicinfo-left">Instructor:</span>
                            <span className="courseinfo-basicinfo-right">Willy Wonka</span>
                        </p>

                        {/*Duration information */}
                        <p className="courseinfo-basicinfo-row">
                            <span className="courseinfo-basicinfo-left">Time to Complete:</span>
                            <span className="courseinfo-basicinfo-right">{duration} hour{duration>1?"s":""}</span>
                        </p>

                        {/*Number of lectures information */}
                        <p className="courseinfo-basicinfo-row">
                            <span className="courseinfo-basicinfo-left">Number of Videos:</span>
                            <span className="courseinfo-basicinfo-right">{courseVideos.length}</span>
                        </p>

                        {/*Teaching medium information */}
                        <p className="courseinfo-basicinfo-row">
                            <span className="courseinfo-basicinfo-left">Teaching Medium:</span>
                            <span className="courseinfo-basicinfo-right">Whiteboard</span>
                        </p>

                        
            
                    </div>

                </div>

            </div>



            {/*Course overview*/}
            <div className="courseinfo-middlecontainer">

                <div className="courseinfo-detailedinfo">
                    <p className="course-overview-title">Course Overview: </p>
                    <div className="course-overview-descr">
                        <pre>{description}</pre>
                    </div>

                    <p className="course-overview-prereqtitle">Recommended Prerequisites:</p>
                    <ul className="course-overview-prereq">
                        <li>Prereq 1</li>
                        <li>Prereq 2</li>
                        <li>Prereq 3</li>
                        {/*props.videoPreReq.map((prereq, index) => <li key={index}>{prereq}</li>)*/}                                           
                    </ul>
                </div>


                {/*Videos SideNav*/}
                <div className="courseinfo-videos-container">

                    <div 
                        className="videosidenav-title">
                        <p className="videosidenav-lecturevideos">Lecture Videos</p>

                        <button 
                            className="videosidenav-expandbtn" 
                            onClick={()=>setExpanded(prev=>!prev)}>
                            {expanded?"Hide Titles":"Show Titles"}
                        </button>
                    </div>
                    
                    <div className="courseinfo-videosidenav">
                        <VideoSideNav
                            
                            expanded={expanded}
                            courseId={courseInfo.courseId}
                            courseVideos={courseVideos}
                        />
                    </div>
                    
                </div>
                

            </div>
        </div>   
    )
}


