import React from "react";
import VideoGrid from "../VideoGrid.js";

import axios from "axios";

export default function RecCourses(props) {
    const [recCourses, setRecCourses] = React.useState([]);

    return (
        <div className="rec-courses">
            <p className="rec-courses-title">Recommended For You</p>
            <VideoGrid coursesData={recCourses} />
        </div>
    )
}