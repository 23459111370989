import React from "react";
import FavCourses from "./FavCourses.js";
import RecCourses from "./RecCourses.js";

export default function HomePage(props) {
    return (
        <div>
            <FavCourses 
                userInfo={props.userInfo}
                setUserInfo={props.setUserInfo}
            />
            <RecCourses
                userInfo={props.userInfo}
                setUserInfo={props.setUserInfo}
            />
        </div>
    )
}