import React from "react";


import chatbotIcon from "../../images/landingpage/landing-chatbot.png";
import chatbotIconClicked from "../../images/landingpage/landing-chatbot-focus.png";

import notesIcon from "../../images//landingpage/landing-notes.png";
import notesIconClicked from "../../images//landingpage/landing-notes-focus.png";

import codingIcon from "../../images/landingpage/landing-code.png";
import codingIconClicked from "../../images/landingpage/landing-code-focus.png";

import settingsIcon from "../../images/landingpage/landing-layout.png";
import { defaultProps } from "react-quill";


export default function VpSidebar(props) {

    //handles adding or removing a component
    function handleToggle(comp) {
        if (props.allComps.includes(comp)) {
            props.handleRemove(comp)
        }
        else {
            props.handleAdd(comp)
        }
    }
    
    return (
        <div className="vp-sidebar">

            <div className="vp-sidebar-direct" onClick={() => handleToggle('chatbot')}>
                <img 
                    src={props.allComps.includes('chatbot')?chatbotIconClicked:chatbotIcon} 
                    className="vp-sidebar-direct-img"/>
            </div>


            <div className="vp-sidebar-direct" onClick={() => handleToggle('notes')}>
                <img 
                    src={(props.allComps.includes('notes'))? notesIconClicked:notesIcon}
                    className="vp-sidebar-direct-img"/>
            </div>

            <div className="vp-sidebar-direct" onClick={() => handleToggle('codeeditor')}>
                <img
                    src={(props.allComps.includes('codeeditor'))?codingIconClicked:codingIcon}
                    className="vp-sidebar-direct-img"/>
            </div>

            <div className="vp-sidebar-direct">
                <img 
                    src={settingsIcon}
                    className="vp-sidebar-direct-img"/>
            </div>
        </div>
    )
}