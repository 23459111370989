import React from "react";
import {Link} from "react-router-dom";

import lpIntroImg from "../../images/landingpage/landingintro.jpg";

export default function LpIntro() {
    return (
        <div className="lp-intro">
            <img src={lpIntroImg} className="lp-intro-img" />

            {/* Text and button on right*/}
            <div className="lp-intro-right">

                <p className="lp-intro-text">
                    Disover Courses, Learn For Free.
                </p>

                <p className="lp-intro-text-small">
                    Explore thousands of top university courses and connect with vibrant communities of other leaners. 
                </p>

                <Link to="/Home">
                <button className="lp-intro-btn">
                    Sign Up for Free
                </button>
                </Link>
            </div>
        </div>

    )
}